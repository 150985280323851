import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://mmp.alfonsocvu.me/"
      }}>{`mmp`}</a></h1>
    <p>{`I created this project because python is laking some CLI solutions like npm, cocoa pods, Gradle, etc.
the community on python is strong and big in the Open Source like JS, so this type of CLI programs that
helps taking care of the packages/modules/libraries it's a most nowadays.`}</p>
    <p>{`I'm a web developer most of the time at my job, but I did not forget about the big leets that python is
doing on the scripting/AI room, and the program handles this great.`}</p>
    <h2>{`Example of use`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ mmp init
$ mmp upgrade pip
$ mmp install fastapi
$ mmp install requests
`}</code></pre>
    <p>{`This will create a virtualenv and a requirements.txt with the fast API and requests modules in it if you
want to run a script our your server just run this command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ mmp run
`}</code></pre>
    <p>{`If you want to know more about the program look into the `}<a parentName="p" {...{
        "href": "https://mmp.alfonsocvu.me/"
      }}>{`docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      